$(document).on('ready', function () {
    // initialization of HSScrollNav component
    $.HSCore.components.HSScrollNav.init($('.js-scroll-nav'), {
        duration: 700
    });

    setTimeout(function () {
        $.HSCore.components.HSStickyBlock.init('.js-sticky-block');
    }, 300);
});
