import ClipboardJS from "clipboard";
import animateScrollTo from "animated-scroll-to";

function initClipboard() {
    var clipboard = new ClipboardJS(".clipboardjs");

    clipboard.on("success", function(e) {
        var classChange = document.querySelector(
            e.trigger.attributes["data-class-change-target"].value
        );
        const targetClass = e.trigger.attributes["data-success-class"].value;
        const defaultClass = e.trigger.attributes["data-default-class"].value;
        classChange.className = targetClass;

        setTimeout(function() { classChange.className = defaultClass; }, 1600);

        e.clearSelection();
    });
}

function handleGoTo(evt) {
    const element = $(this);
    animateScrollTo(document.querySelector(element.data("target")));
    evt.preventDefault();

    return false;
}

function initGoTo() {
    $(".js-go-to").each(function (i, el) {
        var element = $(el);
        if (element.data("fixed")) {
            $(window).on("scroll", function () {
                const requiredOffset = element.data("offset-top");
                const currentOffset = $(window).scrollTop();

                if (currentOffset >= requiredOffset) {
                    // Show it
                    element.show();
                }
                else if (currentOffset < requiredOffset) {
                    // Hide it
                    element.hide();
                }
            });
        }

        element.click(handleGoTo);
    });
}

function initAll() {
    initClipboard();
    initGoTo();
}

$(document).on("ready", initAll);
