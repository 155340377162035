function bind_document_details_controls() {
    $(".document-detail-handler").click(show_document_details);
}

function show_document_details(evt) {
    evt.preventDefault();

    var target = $(this).data("target");
    var el = $("#" + target);
    el.toggle(200);
}


$(document).ready(function() {
    bind_document_details_controls();
});
